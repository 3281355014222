import type {Reservation} from '../../models';

const validColor = (color: string) => {
    if (color && !color.startsWith('#')) {
        color = `#${color}`;
    }
    return color;
};

// any until global reservation type
const colors = (reservation: Reservation) => {
    const settings = reservation.settings;
    const primaryColor = reservation.company?.color_primary;
    let source = reservation.source;

    if (settings?.force_guest_portal_company_brand) {
        source = 'website';
    }

    const defaultColor = primaryColor ? validColor(primaryColor) : '#013D8B';
    const colors = {
        'booking.com': '#013D8B',
        bookingcom: '#013D8B',
        expedia: '#072F54',
        airbnb: '#FF656A',
        website: primaryColor ? validColor(primaryColor) : defaultColor,
        manual: primaryColor ? validColor(primaryColor) : defaultColor,
    };

    source = source?.toLowerCase();

    // Use type assertion to dodge error
    return colors[source as keyof typeof colors] || defaultColor;
};

export default colors;
