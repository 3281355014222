// Material-UI components
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
// Material-UI styles
import {alpha, makeStyles, withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import React, {Fragment} from 'react';
import CDNImage from '../../components/CDNImage';
import bookingSourceImages from '../../containers/GuestPortal/images';
import {isWhite} from '../../libs/utils';
// components
import Topbar from './components/Topbar';
import styles from './styles';
import type {GuestPortalLayoutProps, StylesType} from './types/index';

const progressStyles = (color: string) =>
    makeStyles({
        barColorPrimary: {
            backgroundColor: alpha(color, 0.5),
        },
        page: {
            backgroundColor: color,
        },
    });

let GuestPortalLayout: React.FC<GuestPortalLayoutProps> = (props) => {
    const {
        classes,
        reservation,
        onBack,
        color,
        paintBackground = false,
        progress,
        isMobile,
        isEmbedded,
        hideTopbar = false,
        onInfo,
        children,
        useScroll = false,
        overlay,
    } = props;

    const logoInverted = bookingSourceImages(reservation, true);
    const dynamicClass = progressStyles(color)();

    if (isMobile) {
        return (
            <div className={classNames(classes.root, {[dynamicClass.page]: paintBackground})}>
                {!hideTopbar && (
                    <Fragment>
                        <Topbar
                            overlay={overlay}
                            onBack={onBack}
                            onInfo={onInfo}
                            bookingSourceImage={logoInverted || undefined}
                            reservation={reservation}
                            rootProps={{style: {backgroundColor: color}}}
                        />
                        {!paintBackground && progress && (
                            <LinearProgress
                                variant="determinate"
                                value={typeof progress === 'number' ? progress : Number(progress)}
                                classes={!isWhite(color) ? {barColorPrimary: dynamicClass.barColorPrimary} : undefined}
                            />
                        )}
                    </Fragment>
                )}
                <Container
                    maxWidth={props.maxWidth ? props.maxWidth : isEmbedded ? 'lg' : 'sm'}
                    className={classNames({[classes.embedded]: isEmbedded}, {[dynamicClass.page]: paintBackground})}
                >
                    {children}
                </Container>
            </div>
        );
    } else {
        // not isMobile
        return (
            <div
                className={classNames(
                    classes.root,
                    {[classes.rootWithProgress]: !hideTopbar && !paintBackground},
                    {[dynamicClass.page]: paintBackground},
                )}
            >
                {!hideTopbar && progress && !paintBackground && (
                    <LinearProgress
                        variant="determinate"
                        value={typeof progress === 'number' ? progress : Number(progress)}
                    />
                )}

                <Grid container className={classes.grid}>
                    <Grid item xs={props.hideImage ? 12 : 6}>
                        {!hideTopbar && (
                            <Topbar
                                overlay={overlay}
                                onBack={onBack}
                                onInfo={onInfo}
                                reservation={reservation}
                                rootProps={{style: {backgroundColor: 'transparent'}}}
                                isDesktop={true}
                            />
                        )}
                        <Container
                            maxWidth={props.maxWidth ? props.maxWidth : isEmbedded ? 'lg' : 'sm'}
                            className={classNames(
                                {[classes.embedded]: isEmbedded},
                                {[dynamicClass.page]: paintBackground},
                                {[classes.useScroll]: typeof useScroll !== 'undefined' ? useScroll : false},
                            )}
                        >
                            {children}
                        </Container>
                    </Grid>
                    {!props.hideImage && (
                        <Grid item xs={6} className={classes.listingImageWrapper}>
                            <CDNImage
                                src={reservation.listing_picture_large as string}
                                className={classes.listingImage}
                                userId={reservation.hid || undefined}
                                listingId={reservation.lid || undefined}
                                size="original"
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
};

export {GuestPortalLayout};
export default withStyles(styles as StylesType)(GuestPortalLayout);
