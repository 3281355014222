import type {Theme} from '@material-ui/core';

const styles = (theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: '100vh',
    },
    rootWithProgress: {
        height: 'calc(100vh - 4px)',
    },
    grid: {
        height: '100%',
    },
    content: {
        backgroundColor: theme.palette.common.white,
        paddingTop: '20px',
        // height: '100%',
    },
    listingImageWrapper: {
        overflow: 'hidden',
        width: '100%',
        height: '100%',
    },
    listingImage: {
        // @ts-ignore - neutral exists but is not on Theme type
        backgroundColor: theme.palette.common.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    useScroll: {
        maxHeight: '90vh',
        overflow: 'scroll',
        overflowX: 'hidden',
        paddingRight: '10px',
    },
});

export default styles;
