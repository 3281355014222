import type { Reservation } from '../../models';

//@ts-ignore
const images = (reservation: Reservation = {}, inverted: boolean = false) => {
    const settings = reservation.settings;

    let source = reservation.source;
    if (source) {
        source = source.toLowerCase();
    }

    const images = {
        // 'booking.com': '/static/booking-sources/bookingcom_logo.png',
        // 'bookingcom': '/static/booking-sources/bookingcom_logo.png',
        // 'canadastays': '/static/booking-sources/bookingcom_logo.png',
        // 'airbnb': '/static/booking-sources/airbnb_logo.svg',
        // 'expedia': '/static/booking-sources/expedia_logo.png',
        website: reservation.company && reservation.company.logo_url,
        manual: reservation.company && reservation.company.logo_url,
    };

    const invertedImages = {
        // 'booking.com': '/static/booking-sources/white_bookingcom_logo.png',
        // 'bookingcom': '/static/booking-sources/white_bookingcom_logo.png',
        // 'canadastays': '/static/booking-sources/white_bookingcom_logo.png',
        // 'airbnb': '/static/booking-sources/white_airbnb_logo.png',
        // 'expedia': '/static/booking-sources/white_expedia_logo.png',
        website: reservation.company && reservation.company.logo_contrast_url,
        manual: reservation.company && reservation.company.logo_contrast_url,
    };

    if (settings?.force_guest_portal_company_brand) {
        source = 'website';
    }

    if (inverted) {
        // Use type assertion to dodge errors
        source = invertedImages[source as keyof typeof invertedImages] ? source : 'website';
        if (invertedImages[source as keyof typeof invertedImages]) {
            return invertedImages[source as keyof typeof invertedImages];
        }
    }

    if (!images[source as keyof typeof images]) {
        source = 'website';
    }

    return images[source as keyof typeof images];
};

export default images;
